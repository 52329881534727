export default {
	"id": "zh_cn",
	"signal": "简体中文",

	"page.index.title": "万千世界，遇见你真好",
	"page.index.lores": [
		"这里是酱油瓶的小网站，欢迎访问"
	],
	"page.index.doc_title": "soy-bottle 主页",

	"nav.title.index": "主页",
	"nav.title.wishes": "星语心愿",
	"nav.title.accounts": "社交账号",
	"footer.opensource": "网页源代码在此",



}