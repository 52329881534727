export default {
	"id": "zh_hk",
	"signal": "繁體中文",

	"page.index.title": "你好，這裏是醬油瓶~",
	"page.index.lores": [
		"這裏是醬油瓶的小網站，歡迎訪問"
	],
	"page.index.doc_title": "soy-bottle 主頁",
	"nav.title.index": "主頁",
	"nav.title.wishes": "星語心願",
	"nav.title.accounts": "社交賬號",
	"footer.opensource": "網站源代碼"
}