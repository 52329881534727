export default {
	"id": "en_us",
	"signal": "English",

	"page.index.title": "Welcome to soy-bottle's web",
	"page.index.lores": [
		"this is my website,content for me"
	],
	"page.index.doc_title": "soy-bottle MainPage",

	"nav.title.index": "MainPage",
	"nav.title.wishes": "Wishes",
	"nav.title.accounts": "LinkMe",
	"footer.opensource": "source code",

}